import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import AKSMenu from "./components/aks_sidemenu"


const Artists = () => (
  <Layout>
    <Seo title="Artists" bodyClass="stp" />

    <div className="column column--sidebar alignright">
      <div className="sticky">
        <AKSMenu />
      </div>
    </div>

    <div className="column column--content">
    <div>
      
      <span className={style.titleLowercase}>Artists</span>
        <p>We understand the need for unique talent to shine but sometimes uniqueness can get overshadowed. So the S-T-P family is now representing some of these young talents in hopes that their voices will be heard by the masses!</p>
        <p>If you are a brand new artist just starting out that would like the chance to be developed and possibly even have your music distributed nationally contact us today to schedule your audition and receive more specific info about this opportunity: <a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com</a></p>
      </div>

      <hr/>

      <h2 className="mb--4">Current Artists</h2>
      
      <h3 className="mb--1">SpecyalT</h3>

      <p className="mb--0"><small><strong>Discography</strong></small></p>
      
      <div className="grid grid--half mb--6">


         <div>


         <p className="small">
           Double Take Single (2022)<br/>
          Lowkey Single (2022)<br/>
          Slight Detour EP (2021)<br/>
          Midnight Shade Single (2021)<br/>
          A Specyal Remedy LP (2020)<br/>
          Box Food Single (2020)<br/>
          Bedroom Bully Single (2019)<br/>
          Girl Friday EP (2019)<br/>
          Sunday Siesta Single (2019)<br/>
          Lady Shame Single (2018)<br/>
          Tionne EP (2017)<br/>
          Side Effects Single (2017)<br/>
          C.A.K.E. EP (2015)<br/>
        </p>
        </div>

        <div>
        <p className="small">
          Just A Thot Single (2015)<br/>
          Dose Of Distinction 2 LP - Blackstream Records (2014)<br/>
          Almost Lover Single - Blackstream Records (2014)<br/>
          Dose Of Distinction LP [Digital Release] - Interscope Digital (2012)<br/>
          Airtight (Remix) Single - Interscope Digital (2012)<br/>
          She Said! Mixtape EP (2011)<br/>
          Dose Of Distinction LP - Universal (2010)<br/>
          Sweat It Up! EP - Universal (2009)<br/>
          Sweat It Up! Single - Universal (2009)<br/>
          Ice Cream Cone Single - Universal  (2009)<br/>
          Ontario In Your Stereo Mixtape EP (2008)</p>

        </div>
      </div>
      
      <h3 className="mb--1">Chasing Sunday</h3>

        
        
      <div className="grid grid--half mb--6">
        <div>

        <p className="small">Chasing Sunday is a pop-rock band out of Toronto, fronted by the versatile, award winning music artist Specyal T. This unique outfit provides audiences with distinct melodies, clever lyrics and memorable performances with their original material. Bringing smooth blends of Hip-Hop & RnB mixed with Reggae, Pop, Jazz and Alternative Rock.They continue to bring a fresh sound to the live stage featuring some of the GTA’s most talented musicians.</p>
        

        </div>
        <div>

        <p className="small"><strong>Discography</strong><br/>
          No Chaser (Demo Album) 2021<br/>
          Sight Lines (Demo Album) 2018
          </p>
          <p className="small"> <strong>Links</strong><br/>
          <a href="https://youtu.be/qooWjfIJbp0" target="_blank" rel="noreferrer">Walk Away</a><br/>
          <a href="https://youtu.be/l8-IopODaVw" target="_blank" rel="noreferrer">Wah Mi Seh</a><br/>
          <a href="https://youtu.be/rRsI-AOsGWU" target="_blank" rel="noreferrer">Lately</a><br/>
          <a href="https://youtu.be/kxBgwe2H0Po" target="_blank" rel="noreferrer">Suh Gud</a>
          </p>
        </div>

</div>


      <hr/>
      
      <h2 className="mb--4">Past Artists</h2>

      <div className="grid grid--half">

        <div>
          
          <h3>G.W.A.N. (Specyal T &amp; Ms. Nikki)</h3>
          <p><small><strong>Discography</strong><br/>
          Gwan Wid It Single (2007)<br/>
          Wet Me Single (2007)<br/>  
          </small></p>

         </div>

        <div>
          <h3>Catherine Marie</h3>
          <p><small><strong>Discography</strong><br/>
          Go Tell Them EP (2012)<br/>
          Go Tell Them [Groove To This] Single (2012)<br/>
          Lie To Me Single (2010)</small></p>

         </div>

      </div>





    </div>
  </Layout>
)

export default Artists